import React from 'react'

export default function Logo ( { div, logo }) {
	return (
		<div className={div}>
			<svg version="1.1" id="Capa_1" x="0px" y="0px" className={logo} width="438.529px" height="438.529px" viewBox="0 0 438.529 438.529">
<g>
	<path d="M349.459,52.534c-10.663-10.657-23.605-15.987-38.834-15.987c-15.222,0-28.165,5.327-38.825,15.987
		c-10.656,10.657-15.984,23.598-15.984,38.828c0,9.897,2.467,19.081,7.416,27.55c4.948,8.47,11.604,15.086,19.985,19.842
		c0,9.897-0.805,18.608-2.42,26.125c-1.622,7.517-4.284,14.128-7.994,19.842c-3.72,5.711-7.566,10.561-11.566,14.56
		c-4.001,3.999-9.616,7.755-16.848,11.278c-7.231,3.521-13.945,6.468-20.129,8.851c-6.184,2.375-14.514,5.182-24.982,8.419
		c-19.036,5.903-33.689,11.323-43.968,16.275V102.206c8.375-4.755,15.037-11.37,19.985-19.84c4.947-8.47,7.421-17.655,7.421-27.552
		c0-15.225-5.327-28.169-15.987-38.826C156.073,5.332,143.132,0,127.903,0c-15.23,0-28.171,5.328-38.831,15.988
		C78.416,26.645,73.085,39.589,73.085,54.814c0,9.897,2.474,19.082,7.421,27.552c4.948,8.47,11.609,15.085,19.985,19.84v234.117
		c-8.376,4.753-15.037,11.375-19.985,19.842c-4.947,8.473-7.421,17.658-7.421,27.552c0,15.225,5.327,28.168,15.987,38.824
		s23.604,15.988,38.831,15.988c15.226,0,28.17-5.332,38.826-15.988c10.657-10.656,15.987-23.6,15.987-38.824
		c0-9.894-2.474-19.079-7.421-27.552c-4.949-8.467-11.61-15.089-19.985-19.842V328.9c0-13.131,3.949-22.645,11.847-28.544
		c7.898-5.907,24.029-12.662,48.395-20.273c25.699-8.186,45.021-15.899,57.963-23.134c42.633-24.167,64.142-63.568,64.521-118.196
		c8.381-4.755,15.037-11.372,19.985-19.842c4.945-8.47,7.423-17.653,7.423-27.55C365.447,76.135,360.116,63.194,349.459,52.534z
		 M147.321,403.138c-5.332,5.331-11.803,7.994-19.414,7.994c-7.616,0-14.087-2.663-19.417-7.994
		c-5.327-5.325-7.994-11.8-7.994-19.411c0-7.617,2.664-14.085,7.994-19.417c5.33-5.328,11.801-7.994,19.417-7.994
		c7.611,0,14.083,2.669,19.414,7.994c5.33,5.332,7.993,11.8,7.993,19.417C155.313,391.338,152.651,397.812,147.321,403.138z
		 M147.321,74.232c-5.332,5.33-11.803,7.994-19.414,7.994c-7.616,0-14.087-2.664-19.417-7.994
		c-5.327-5.33-7.994-11.798-7.994-19.414c0-7.614,2.664-14.087,7.994-19.412c5.33-5.329,11.801-7.994,19.417-7.994
		c7.611,0,14.083,2.666,19.414,7.994c5.33,5.325,7.993,11.798,7.993,19.412C155.313,62.434,152.651,68.905,147.321,74.232z
		 M330.042,110.779c-5.328,5.327-11.796,7.993-19.41,7.993c-7.618,0-14.09-2.666-19.414-7.993
		c-5.328-5.327-7.994-11.799-7.994-19.414c0-7.614,2.666-14.083,7.994-19.414s11.796-7.993,19.414-7.993
		c7.614,0,14.082,2.663,19.41,7.993c5.328,5.326,7.994,11.799,7.994,19.414C338.036,98.979,335.374,105.452,330.042,110.779z"/>
</g>
</svg></div>)
}
